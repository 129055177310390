// Body1.js
import React from 'react';
import './Component.css';

function Body1() {
  return (
    <div className="body1">
      <div className="group">
        <img className="group-img" src={`${process.env.PUBLIC_URL}/Body1/people.svg`} alt="Community and Collaboration"/>
        <h2>Community and Collaboration</h2>
        <p>Join our community of programmers, where you can share knowledge, seek guidance, and collaborate for mutual growth.</p>
      </div>
      <div className="group">
        <img className="group-img" src={`${process.env.PUBLIC_URL}/Body1/puzzle-piece.svg`} alt="Real world Programming"/>
        <h2>Real world Programming</h2>
        <p>Master essential programming skills employers seek through our interactive challenges and hands-on projects.</p>
      </div>
      <div className="group">
        <img className="group-img" src={`${process.env.PUBLIC_URL}/Body1/handshake.svg`} alt="Career Support"/>
        <h2>Career Support</h2>
        <p>Get personalized guidance and connections with companies to launch your programming career with confidence.</p>
      </div>
    </div>
  );
}

export default Body1;
