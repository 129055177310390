// PricingPage.js
import React from 'react';
import ComingSoon from './Components/ComingSoon';

function PricingPage() {
  return(
    <ComingSoon />
  );
}

export default PricingPage;
