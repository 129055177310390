// App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Canvas } from '@react-three/fiber';
import PricingPage from './pages/PricingPage';
import AboutPage from './pages/AboutPage';
import SupportPage from './pages/SupportPage';
import SignupPage from './pages/SignupPage';
import HomePage from './pages/HomePage';
import PythonLesson from './pages/PythonLesson';
import Header from './pages/Components/Header';
import Footer from './pages/Components/Footer';
import Cube from './Cube';
import Login from './pages/LoginPage'; 
import UnityPage from './pages/UnityPage';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import './styles.css';

function App() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <Router>
      <Header user={user} />
      <Routes>
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/support" element={<SupportPage />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/python-lesson" element={<PythonLesson />} /> 
        <Route path="/unity" element={<UnityPage />} /> 
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<Login />} />
      </Routes>
      <Canvas>
        <ambientLight />
        <pointLight position={[10, 10, 10]} />
        <Cube />
      </Canvas>
      <Footer />
    </Router>
  );
}

export default App;
