// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth"; // Import getAuth

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDwTAyBCukCJpTkqJEBEUe-2Is36bUetGE",
  authDomain: "ozone-6b8da.firebaseapp.com",
  projectId: "ozone-6b8da",
  storageBucket: "ozone-6b8da.appspot.com",
  messagingSenderId: "230051484986",
  appId: "1:230051484986:web:85748816895cefc7076c03",
  measurementId: "G-6G5KQQMJ58"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const auth = getAuth(); // Create an auth instance

export { auth }; // Export auth
