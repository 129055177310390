// Header.js
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import './Component.css'; // Import the CSS file here

function Header() {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    auth.onAuthStateChanged((currentUser) => {
      setUser(currentUser);
    });
  }, []);

  const logout = () => {
    const auth = getAuth();
    signOut(auth)
      .then(() => {
        console.log('User signed out');
      })
      .catch((error) => {
        console.error('Error signing out: ', error);
      });
  };

  return (
    <header className="header">
      <Link to="/" className="logo-link">
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/OzoneLogo.png`} alt="Logo" className="logo" />
          <h1 className="logo-text">Ozone</h1>
        </div>
      </Link>
      <nav>
        <Link to="/about" className="top-links">About</Link>
        <Link to="/pricing" className="top-links">Pricing</Link>
        <Link to="/support" className="top-links">Support</Link>
        {user ? (
          <button onClick={logout} className="signup-btn">Logout</button>
        ) : (
          <Link to="/login" className="signup-btn">Login</Link>
        )}
      </nav>
    </header>
  );
}

export default Header;
