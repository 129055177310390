/* global brython, __BRYTHON__ */
import React, { useState, useEffect, useRef } from 'react';
import MonacoEditor from 'react-monaco-editor';
import './PythonLesson.css';
import { Editor } from '@monaco-editor/react';


const initialCodes = [
  {
    code: `\n# 1. Printing Output and Variables\nprint("Hello, World!")\nname = "Alice"\nprint("Hello,", name)`,
    description: "In this part of the lesson, we'll learn how to print output and work with variables. The command `print(\"Hello, World!\")` prints the text \"Hello, World!\". Next, we create a variable `name` and assign it the value \"Alice\". We then print a greeting using the variable."
  },
  {
    code: `\n# 2. Basic Data Types\nmy_int = 10\nmy_float = 10.5\nmy_string = "Hello, World!"\nmy_bool = True\n\n# Print out the types\nprint(type(my_int))\nprint(type(my_float))\nprint(type(my_string))\nprint(type(my_bool))`,
    description: "Next, we'll explore basic data types in Python. We assign integer, float, string, and boolean values to `my_int`, `my_float`, `my_string`, and `my_bool`, respectively. Then we print out the types of these variables using the `type()` function."
  },
  {
    code: `\n# 3. Basic Operations\nnum1 = 10\nnum2 = 5\n\n# Arithmetic Operations\nprint(num1 + num2)  # Addition\nprint(num1 - num2)  # Subtraction\nprint(num1 * num2)  # Multiplication\nprint(num1 / num2)  # Division\n\n# String Operations\nstr1 = "Hello,"\nstr2 = " World!"\nprint(str1 + str2)  # Concatenation\nprint(str1 * 3)     # Multiplication`,
    description: "Finally, we'll look at some basic operations, including arithmetic and string operations. We assign two numbers to `num1` and `num2`, then perform and print the results of addition, subtraction, multiplication, and division. For string operations, we assign two strings to `str1` and `str2`, then perform concatenation and multiplication, printing the results."
  }
];

const PythonBlock = ({ initialCode }) => {
  const [output, setOutput] = useState('');
  const editorRef = useRef(null);
  const outputRef = useRef(null);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setValue(initialCode);
    }
  }, [initialCode]);

  const runCode = () => {
    outputRef.current.innerText = ""; // clear previous output
    let pythonCode = editorRef.current.getValue();
    pythonCode += '\n\nprint = lambda *args, sep=" ", end="\\n": __BRYTHON__.JSObject(outputRef.current).innerText += sep.join(map(str,args))+end';
    
    try {
      brython(); // make sure Brython is loaded
      eval(__BRYTHON__.py2js(pythonCode, '__main__', '__main__').to_js());
    } catch (error) {
      console.error('Brython error:', error);
    }
  };
  
  
  return (
    <div className="python-block">
      <div className="editor">
        <Editor
          width="100%"
          height="400px"
          language="python"
          theme="vs-dark"
          value={initialCode}
          onChange={(value) => {}}
          editorDidMount={(editor, monaco) => {
            editorRef.current = editor;
          }}
          options={{
            fontSize: 18,
            automaticLayout: true,
          }}
        />
        <div className="output">
          <h2>Output:</h2>
          <pre id="output" ref={outputRef}>{output}</pre>
        </div>

      </div>
    </div>
  );
};


const PythonLesson = () => {
  return (
    <div className="python-lesson">
      <h1>Introduction to Python</h1>
      <p>In this lesson, you will learn the basics of Python programming.</p>

      <h2>Lesson 1: Introduction to Python</h2>
      <p>Objective:</p>
      <ul>
        <li>Introduce Python as a beginner-friendly programming language.</li>
        <li>Set up the Python development environment.</li>
        <li>Learn how to print output and work with variables.</li>
        <li>Understand basic data types and perform basic operations.</li>
      </ul>

      <div className="coding-environment">
        {initialCodes.map(({code, description}, index) => (
          <div key={index}>
            <p>{description}</p>
            <PythonBlock initialCode={code} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PythonLesson;
