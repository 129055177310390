import React from 'react';
import Hero from './Components/Hero';
import Body1 from './Components/Body1';
import Body2 from './Components/Body2';
import SignupForm from './Components/SignUpForm';

function HomePage() {
  return (
    <div>
      <Hero />
      <Body1 />
      <Body2 />
      <SignupForm />
    </div>
  );
}

export default HomePage;


