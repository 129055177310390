import React from 'react';
import './Component.css';

const handleTwitterClick = () => {
  window.location.href = 'https://twitter.com/ozonetwork';
};

function Footer() {
  return (
    <div className="footer">
      <div className="line" />
      <div className="foot-text">
        <div className="logo-container">
          <img src={`${process.env.PUBLIC_URL}/OzoneLogo.png`} alt="Logo" className="logo" />
          {/* <h1 className="logo-text">Ozone</h1> */}
        </div>
        <div className="text-group-foot">
          <h2 className="foot-title">Community</h2>
          <p className="foot-content">Events</p>
          <p className="foot-content">Upcoming Updates</p>
        </div>
        <div className="text-group-foot">
          <h2 className="foot-title">Company</h2>
          <p className="foot-content">About Us</p>
          <p className="foot-content">Contact Us</p>
        </div>
        <div className="text-group-foot">
          <h2 className="foot-title">Website</h2>
          <p className="foot-content">Pricing</p>
          <p className="foot-content">Support</p>
        </div>
        <div className="follow-us">
          <h2 className="foot-title">Follow Us:</h2>
          <a href="https://twitter.com/ozonetwork" target="_blank" rel="noopener noreferrer">
            <img src={`${process.env.PUBLIC_URL}/twitter.png`} alt="Twitter" onClick={handleTwitterClick} className="twitter"/>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
