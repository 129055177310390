import React from 'react';
import './Component.css';

function ComingSoon() {
    return (
        <div className="coming-soon">
            <img src={`${process.env.PUBLIC_URL}/OzoneDoor.jpg`} alt="Coming Soon!" className="door"/>
            <h2 className="soon-text">Exciting things are on the horizon!</h2>
            <p className="soon-text">Be sure to sign up for our mailing list and check back frequently to see what we have in store for you next!</p>
        </div>
    );
  }
  
  export default ComingSoon;
  