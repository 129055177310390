import React, { useState } from 'react';
import './Component.css';

function SignupForm() {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    // Make a POST request to the server with the email
    fetch('http://localhost:5001/api/mailing-list', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Log the response from the server
        // Reset the email input
        setEmail('');
        setSubmitted(true);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="signup">
      {submitted ? (
        <p className="submit-message">Thank you for signing up! We will send you a confirmation email shortly.</p>
      ) : (
        <>
          <p className="signup-text">
            Sign up now to stay in the loop and be the first to receive exclusive promotions and exciting news about Ozone
          </p>
          <div>
            <input
              type="email"
              name="email"
              value={email}
              onChange={handleChange}
              placeholder="example@email.com"
              className="email-input"
            />
            <input type="submit" value="Submit" onClick={handleSubmit} className="submit-button" />
          </div>
        </>
      )}
    </div>
  );
}

export default SignupForm;
