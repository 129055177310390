import React from 'react';
import './Component.css';

function Body2() {
  return (
    <div>
      <div className="how-it-works">
        <h1 className="section-title">How It Works:</h1>
        <div className="underline"></div>
      </div>

      <div className="body2">
        <div className="step">
          <div className="image">
            <img className="image-img" src={`${process.env.PUBLIC_URL}/Body2/Stair.png`} alt="Step 1" />
          </div>
          <div className="text-group">
            <h2>Learning the Fundamentals</h2>
            <p>If you are a beginner or just need a refresher, we got you! Master fundamental concepts through challenges and coding exercises. Build a solid foundation for your programming journey.</p>
          </div>
        </div>
        <div className="step reverse">
          <div className="image">
            <img className="image-img2" src={`${process.env.PUBLIC_URL}/Body2/Programming.png`} alt="Step 2" />
          </div>
          <div className="text-group">
            <h2>Real-World Experience</h2>
            <p>Apply your skills to real-world gigs, collaborate with peers, and tackle industry-relevant tasks. Prepare yourself for the demands of the programming world while expanding your portfolio.</p>
          </div>
        </div>
        <div className="step">
          <div className="image">
            <img className="image-img2" src={`${process.env.PUBLIC_URL}/Body2/Interview.png`} alt="Step 3" />
          </div>
          <div className="text-group">
            <h2>Assistance With Job Opportunities</h2>
            <p>Our platform connects highly skilled programmers with rewarding career paths. From resume guidance to interview preparation to the interviews themselves, we're here to support your journey to finding your dream programming job.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Body2;
