// AboutPage.js
import React from 'react';
import "./Components/Component.css"

function AboutPage() {
  return(
    <div>
      <div className="how-it-works">
        <h1 className="section-title">Meet the Team:</h1>
        <div className="underline"></div>
      </div>
      <div classname="about">
        <div className="team">
          <div className="team-member">
            <h2 className="team-text">Sebastian</h2>
            <p className="team-text">Co-Founder of Ozone, Specializing in Design. Wants to make programming more achievable for all.</p>
          </div>
          <div className="team-member">
          <img src={`${process.env.PUBLIC_URL}/AboutUs.png`} alt="Us at Table" className="team-photo"/>
          </div>
          <div className="team-member">
            <h2 className="team-text">Chris</h2>
            <p className="team-text">Co-Founder of Ozone, Specializing in Programming. Focused on bringing everyone to a brighter future.</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutPage;
