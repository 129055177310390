// Cube.js
import React, { useRef } from 'react';
import { useFrame } from '@react-three/fiber';

function Cube() {
  const meshRef = useRef();

  useFrame(() => {
    meshRef.current.rotation.y += 0.01;
    meshRef.current.rotation.x += -0.01;
  });

  return (
    <mesh ref={meshRef}>
      <boxGeometry args={[1, 1, 1]} />
      <meshStandardMaterial color= "lightblue" />
    </mesh>
  );
}

export default Cube;
