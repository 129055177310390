// SupportPage.js
import React from 'react';
import ComingSoon from "./Components/ComingSoon.js"

function SupportPage() {
  return(
    <ComingSoon />
  );
}

export default SupportPage;
