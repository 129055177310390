import React from 'react';
import './Component.css';
import { Link } from 'react-router-dom';

function Hero() {
  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className="hero">
      <div className="title-container">
        <div className="hero-title">
          <h1>Bringing you from<br />Education to Employment</h1>
        </div>
        <div className="hero-button-container">
          <Link to = "/unity">
            <button>Try Now</button>
          </Link>
        </div>
      </div>
      <img src={`${process.env.PUBLIC_URL}/OzoneBuilding8bit.png`} alt="Building" className="main-photo" />
    </div>
  );
}

export default Hero;
