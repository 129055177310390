import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { Button, TextField, Container, Typography } from '@mui/material'; // Import Material-UI components
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './SignUpPage.css'; // Import custom CSS for SignUpPage

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = (e) => {
    e.preventDefault();
    const auth = getAuth();
    createUserWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // The user is signed up
        const user = userCredential.user;
      })
      .catch((error) => {
        // Handle ErrorsS here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  };

  return (
    <div className="signup-container">
      <Container component="main" maxWidth="xs" className="signup-form">
        <Typography component="h1" variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#3e67b3' }}>
          Sign Up
        </Typography>

        <form onSubmit={onSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="new-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large" // Make the button larger
            className="sign-up-button" // Apply custom CSS class
          >
            Sign Up
          </Button>
          <Typography variant="body2" align="center" mt={2}>
            Already have an account? <Link to="/login" style={{ textDecoration: 'none' }}>Sign in</Link>
          </Typography>
        </form>
      </Container>
    </div>
  );
}

export default SignUp;
