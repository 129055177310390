// LoginPage.js
import React, { useState } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom'; 
import { Button, TextField, Container, Typography, Box } from '@mui/material';
import GoogleButton from 'react-google-button'; 
import { auth } from '../firebase';
import './login-page.css'; 

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();

    signInWithPopup(auth, provider)
      .then((result) => {
        console.log(result.user); 
        navigate('/');
      })
      .catch((error) => {
        console.error(error); 
      });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // The user is signed in
        const user = userCredential.user;
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        // ...
      });
  };

  return (
    <div className="login-container">
      <Container component="main" maxWidth="xs" className="login-form">
        <Typography component="h1" variant="h5" align="center" gutterBottom sx={{ fontWeight: 'bold', color: '#3e67b3' }}>
          Log In
        </Typography>

        
<form onSubmit={onSubmit} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            size="large"
            className="login-button"
            sx={{
                justifyContent: 'center', // center the content of the button
                mt: 2, // margin top 2
            }}
            >
            Log In
            </Button>

            <Box mt={4}> {/* This box is pushing the Google button a bit down */}
          <GoogleButton onClick={signInWithGoogle} />
        </Box>

          <Typography variant="body2" align="center" mt={2}>
            Don't have an account? <Link to="/signup" style={{ textDecoration: 'none' }}>Sign up</Link>
          </Typography>
        </form>

        
      </Container>
    </div>
  );
}

export default Login;


