import React from 'react';
import { Unity, useUnityContext } from 'react-unity-webgl';
import './UnityPage.css'; 

const unityConfig = {
  loaderUrl: "/UnityBuild/Build/UnityBuild.loader.js",
  dataUrl: "/UnityBuild/Build/UnityBuild.data",
  frameworkUrl: "/UnityBuild/Build/UnityBuild.framework.js",
  codeUrl: "/UnityBuild/Build/UnityBuild.wasm",
  streamingAssetsUrl: "/UnityBuild/StreamingAssets"
};


function UnityPage() {
  const { unityProvider } = useUnityContext(unityConfig);

  return (
    <div className="unity-parent-container">
    <div className="unity-container">
        <Unity unityProvider={unityProvider} />
        </div>
    </div>

  );
}

export default UnityPage;
